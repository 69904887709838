import { GetProdcutOrderStatusDto, GetServiceDto } from "@/api-client/api";
import { TelkomProductOrderService } from "@/network/api";
import { handleError } from "@/utils/auth";
import { Message } from "element-ui";
export function TelkomProductOrderServicesCheckOrderStatusProductOrderIdGet(productOrderReferenceNumner: string): Promise<GetProdcutOrderStatusDto | null> {
  return new Promise((resolve, reject) => {
    TelkomProductOrderService.apiTelkomProductOrderServicesCheckOrderStatusProductOrderIdGet(productOrderReferenceNumner)
      .then((res) => {
        if ([200, 201,].includes(res.status)) {
          resolve(res.data)
        }
        else if ([204].includes(res.status)) {
          Message.error({ message: "There was an error on the Telkom API!", showClose: true })
          resolve(res.data)
        }
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  });
}

export function TelkomProductOrderServicesCheckServiceMsisdnGet(msisdn: string): Promise<GetServiceDto | null> {
  return new Promise((resolve, reject) => {
    TelkomProductOrderService.apiTelkomProductOrderServicesCheckServiceMsisdnGet(msisdn)
      .then((res) => {
        if ([200, 201,].includes(res.status)) {
          resolve(res.data)
        }
        else if ([204, 404].includes(res.status)) {
          Message.error({ message: "There was an error on the Telkom API!", showClose: true })
          resolve(res.data)
        }
      })
      .catch((error) => {
        resolve(error.data)
      });
  });
}