
import { Component, Vue } from "vue-property-decorator";
import DialogBox from "@/components/DialogBox.vue";
import Pagination from "@/components/Pagination.vue";
import { GetProdcutOrderStatusDto, GetServiceDto, GetServiceDtoRatePlan, GetServiceDtoSubscriberInfo, TelkomProductOrderServicesApi } from "@/api-client";
import { handleQueries } from "@/utils/handleQueries"
import { ordersPaymentsDueGet, ordersProcessPaymentsPost } from "@/api/orders";
import { formatDate } from "@/utils/formatDate";
import { debounce } from 'vue-debounce'
import { billingDaysDdlGet } from "@/api/payments";
import { TelkomProductOrderServicesCheckOrderStatusProductOrderIdGet, TelkomProductOrderServicesCheckServiceMsisdnGet } from "@/api/telkom-order-service";
import { Rule } from "@/types/async-validator";

@Component({
  name: "Toolbox",
  components: {
    DialogBox,
  },
  filters: {
  }
})
export default class extends Vue {

  filter: string = "";
  billingDayOfMonth: number = 1;
  orderActionId: string | null = null;
  msisdn: string | null = null;
  amount: number | null = null;
  selection: Array<any> = [];
  orderActionDialogVisible = false;
  orderActionResultDialogVisible = false;
  orderStatus : GetProdcutOrderStatusDto | undefined = {
        externalId: "",
        productOrderReferenceNumber: "",
        subscriberStatus: "",
        OrderStatus: "",
        OrderState: "",
        CreatedDate: "",
        DueDate: "",
        UpdatedDate: "",

      } as GetProdcutOrderStatusDto;
  serviceStatus : GetServiceDto | undefined ={ratePlan: {  
        productName: '',  
        effectiveDate: '',  
        expiryDate: '',  
        createDate: '',  
        status: '',  
      } as GetServiceDtoRatePlan,  
      subscriberInfo: {  
        subscriberStatus: 'Active',  
        subscriberType: 'Premium',  
        suspendedReason: 'None',  
        iccid: '12345678901234567890',  
      } as GetServiceDtoSubscriberInfo }as GetServiceDto;;
  disableSubmit: boolean = false;
  serviceDialogVisible: boolean = false;
  serviceResultDialogVisible: boolean = false;

  get params() {
    return {
    }
  }



  async created() {
  }

  mounted() {

  }

  // setQueryWatch(fields: any) {
  //   let queries = this.$route.query;

  //   for (const field in fields) {
  //     if (queries[field]) {

  //       if (typeof (this as any)[field] == 'number') {
  //         (this as any)[field] = +queries[field];
  //       } else {
  //         (this as any)[field] = queries[field];
  //       }
  //     }

  //     this.$watch(field, (val) => {
  //       handleQueries(field, val)
  //     })
  //   }
  // }



  // handleSelect(id: any) {
  //   if (this.selection.includes(id)) {
  //     this.selection = this.selection.filter((item) => item !== id);
  //   } else {
  //     this.selection.push(id);
  //   }
  // }



  get rules(): Record<string, Rule> {
    return {
      orderActionId: { required: true, message: "Please enter an order action id" },
    }
  }
  openQueryOrderDialog() {
    this.orderActionId = "";
    this.orderActionDialogVisible = true;
    this.disableSubmit = false;
  }
  handleGetOrderActionStatusClose() {
    this.orderActionDialogVisible = false;
    this.orderActionId = null;
    this.disableSubmit = false;
  }

  handleGetOrderActionStatusResultClose() {
    this.disableSubmit = false;
    this.orderActionResultDialogVisible = false;

  }

  queryOrderProductStatus() {
    if (this.orderActionId == null || this.orderActionId.length < 1) {
      return;
    }
    this.disableSubmit =  true;
    if (!this.orderActionId?.endsWith("A")) {
      this.orderActionId += "A"
    }
    TelkomProductOrderServicesCheckOrderStatusProductOrderIdGet(this.orderActionId!).then((response) => {
      this.handleGetOrderActionStatusClose();
      console.log(response);
      // response = {
      //   externalId: "12342134-sdfasdf",
      //   productOrderReferenceNumber: "123423423423",
      //   subscriberStatus: "SUBSCRIBER_STATUS_ACTIVE",
      //   OrderStatus: "ORDER_STATUS_COMPLETED",
      //   OrderState: "ORDER_STATE_COMPLETED",
      //   CreatedDate: "some date",
      //   DueDate: "some date",
      //   UpdatedDate: "some date",

      // } as GetProdcutOrderStatusDto;
      if (response) {
        this.orderStatus = response as GetProdcutOrderStatusDto;
        this.orderActionResultDialogVisible = true;
      }
    })
  }


  //service status
  openQueryServiceDialog() {
    this.msisdn = "";
    this.serviceDialogVisible = true;
    this.disableSubmit = false;
  }
  handleGetServiceStatusClose() {
    this.serviceDialogVisible = false;
    this.msisdn = "";
    this.disableSubmit = false;
  }

  handleGetServiceStatusResultClose() {
    this.disableSubmit = false;
    this.serviceResultDialogVisible = false;

  }

  queryServiceStatus() {
    if (this.msisdn == null || this.msisdn.length == 0) {
      return;
    }
    this.disableSubmit =  true;

    TelkomProductOrderServicesCheckServiceMsisdnGet(this.msisdn!).then((response) => {
      this.handleGetServiceStatusClose();
      console.log(response);
      // response = {ratePlan: {  
      //   productName: 'sadf',  
      //   effectiveDate: 'asdf',  
      //   expiryDate: 'sadf',  
      //   createDate: 'sadf',  
      //   status: 'asdf',  
      // } as GetServiceDtoRatePlan,  
      // subscriberInfo: {  
      //   subscriberStatus: 'asdf',  
      //   subscriberType: 'asdf',  
      //   suspendedReason: 'asdf',  
      //   iccid: '123456789098875647',  
      // } as GetServiceDtoSubscriberInfo }as GetServiceDto;
      console.log(response);

      if (response != null || response != undefined) {
        this.serviceStatus = response as GetServiceDto;
        this.serviceResultDialogVisible = true;
      }
    })
  }


}
